<template>
  <a-config-provider :locale="locale === 'zh-cn' ? zhCN : enUS">
    <div class="smart">
      <router-view></router-view>
    </div>
  </a-config-provider>
</template>

<script setup>
import { ref } from "vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import enUS from "ant-design-vue/es/locale/en_US";
const locale = ref(zhCN.locale);

</script>

<style lang="less" scoped>
.smart {
  width: 100%;
  height: 100%;
}
</style>
