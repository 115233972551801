import { createApp } from "vue";
import App from "./App.vue";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import router from "./router";
import { setupStore } from './store/index.js'
import 'animate.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


//重置样式
import "./assets/css/reset.css";

async function bootstrap() {
const app = createApp(App);

setupStore(app)
app.use(router);
app.use(Antd);
app.use(ElementPlus);

app.mount("#app");
}

bootstrap()