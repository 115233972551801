import {
  createRouter,
  createWebHistory /* createWebHashHistory */ ,
} from "vue-router";
const router = createRouter({
  // history: createWebHashHistory(import.meta.env.VITE_PUBLIC_PATH),
  history: createWebHistory(),
  strict: true,
  scrollBehavior: () => ({
    left: 0,
    top: 0
  }),
  routes: [{
    name: "",
    path: "/",
    component: () => import("../views/layout"),
    redirect: "/home",
    children: [{
        path: "/home",
        name: "",
        key: "1",
        component: () => import("../views/home/index.vue"),
      },
      {
        path: "products",
        name: "",
        key: "2",
        component: () => import("../views/products/index.vue"),
        // children: [
        //   {
        //     path: "products/:url",
        //     name: "",
        //     component: () => import("../views/products/Details.vue"),
        //   },
        // ],
      },
      {
        path: "about",
        name: "About",
        key: "3",
        component: () => import("../views/about/index.vue"),
      },
      {
        path: "technical",
        name: "Technical",
        key: "4",
        component: () => import("../views/technical/index.vue"),
      },
      {
        path: "contact",
        name: "Contact",
        key: "5",
        component: () => import("../views/contact/index.vue"),
      },
      {
        path: "/details",
        name: "Details",
        key: "6",
        component: () => import("../views/products/Details.vue"),
      },
    ],
  }, ],
});

export default router;